/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/31/2022
 * Time: 8:47 PM
 * Project: w_crm
 */

import {createSlice} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {endPoints} from "../../api/endpoints";
import {formatAxiosErrorMessage} from "../../utils/common";

const subscriptionPlansState = {
    plan: {
        isLoading: false,
        data: [],
        error: null,
    },
};

const subscriptionTypesState = {
    type: {
        isLoading: false,
        data: [],
        error: null,
    },
};
const subscriptionCalculateState = {
    calculate: {
        isLoading: false,
        data: {
            plan: "",
            start_date: "",
            end_date: "",
            price: "",
            no_of_passes: "",
        },
        error: null,
    },
};
const subscriptionDetailsState = {
    details: {
        isLoading: false,
        data: {
            ownedSubscription: null,
            assignedSubscription: null,
            mappedSubscription: null,
        },
        error: null,
    },
};
const subscriptionCreationState = {
    creation: {
        isLoading: false,
        data: null,
        error: null,
    },
};

const subscriptionPaymentState = {
    payment: {
        isLoading: false,
        data: [],
        error: null,
    },
};

const InvoiceDownloadState = {
    invoiceDownload: {
        isLoading: false,
        data: null,
        error: null,
    },
};

const initialSubscriptionState = {
    ...subscriptionPlansState,
    ...subscriptionTypesState,
    ...subscriptionCalculateState,
    ...subscriptionDetailsState,
    ...subscriptionCreationState,
    ...subscriptionPaymentState,
    ...InvoiceDownloadState
};

export const subscriptionSlice = createSlice({
    name: "subscription",
    initialState: {
        ...initialSubscriptionState,
    },
    reducers: {
        subscriptionPlansRequesting: (state, action) => {
            state.plan.isLoading = true;
        },
        subscriptionPlansSuccess: (state, action) => {
            state.plan.isLoading = false;
            state.plan.error = null;
            state.plan.data = action.payload.data;
        },
        subscriptionPlansError: (state, action) => {
            state.plan.isLoading = false;
            state.plan.data = null;
            state.plan.error = action.payload;
        },
        subscriptionPlansReset: (state, action) => {
            state.plan = subscriptionPlansState.plan;
        },

        subscriptionTypesRequesting: (state, action) => {
            state.type.isLoading = true;
        },
        subscriptionTypesSuccess: (state, action) => {
            state.type.isLoading = false;
            state.type.error = null;
            state.type.data = action.payload.data;
        },
        subscriptionTypesError: (state, action) => {
            state.type.isLoading = false;
            state.type.data = null;
            state.type.error = action.payload;
        },
        subscriptionTypesReset: (state, action) => {
            state.type = subscriptionTypesState.type;
        },

        subscriptionCalculationRequesting: (state, action) => {
            state.calculate.isLoading = true;
        },
        subscriptionCalculationSuccess: (state, action) => {
            state.calculate.isLoading = false;
            state.calculate.error = null;
            state.calculate.data = action.payload.data;
        },
        subscriptionCalculationError: (state, action) => {
            state.calculate.isLoading = false;
            state.calculate.data = null;
            state.calculate.error = action.payload;
        },
        subscriptionCalculationReset: (state, action) => {
            state.calculate = subscriptionCalculateState.calculate;
        },

        subscriptionDetailsRequesting: (state, action) => {
            state.details.isLoading = true;
        },
        subscriptionDetailsSuccess: (state, action) => {
            state.details.isLoading = false;
            state.details.error = null;
            state.details.data.ownedSubscription = action.payload.data.userownedsubscriptionmapping;
            state.details.data.assignedSubscription =
                action.payload.data.subscriptionassignedtousermapping;
            state.details.data.mappedSubscription =
                action.payload.data.usersubscriptionmapping;
        },
        subscriptionDetailsError: (state, action) => {
            state.details.isLoading = false;
            state.details.data = null;
            state.details.error = action.payload;
        },
        subscriptionDetailsReset: (state, action) => {
            state.details = subscriptionDetailsState.details;
        },

        subscriptionCreationRequesting: (state, action) => {
            state.creation.isLoading = true;
        },
        subscriptionCreationSuccess: (state, action) => {
            state.creation.isLoading = false;
            state.creation.error = null;
            state.creation.data = action.payload;
        },
        subscriptionCreationError: (state, action) => {
            state.creation.isLoading = false;
            state.creation.data = null;
            state.creation.error = action.payload;
        },
        subscriptionCreationReset: (state, action) => {
            state.creation.isLoading = false;
            state.creation.data = null;
            state.creation.error = null;
        },

        subscriptionPaymentRequesting: (state, action) => {
            state.payment.isLoading = true;
        },
        subscriptionPaymentSuccess: (state, action) => {
            state.payment.isLoading = false;
            state.payment.error = null;
            state.payment.data = action.payload.data;
        },
        subscriptionPaymentError: (state, action) => {
            state.payment.isLoading = false;
            state.payment.data = [];
            state.payment.error = action.payload;
        },
        subscriptionPaymentReset: (state, action) => {
            state.payment.isLoading = false;
            state.payment.data = [];
            state.payment.error = null;
        },


        downloadInvoiceRequestRequesting: (state, action) => {
            state.invoiceDownload.isLoading = true;
        },
        downloadInvoiceRequestSuccess: (state, action) => {
            state.invoiceDownload.isLoading = false;
            state.invoiceDownload.error = null;
            state.invoiceDownload.data = action.payload.data;
        },
        downloadInvoiceRequestError: (state, action) => {
            state.invoiceDownload.isLoading = false;
            state.invoiceDownload.error = action.payload;
        },
        downloadInvoiceRequestReset: (state) => {
            state.invoiceDownload = subscriptionDetailsState.invoiceDownload;
        },

        SubscriptionMasterReset: () => initialSubscriptionState,
    },
});

export const calculateSubscriptionApi = (data, renew = false) => async (dispatch) => {
    dispatch(subscriptionCalculationRequesting());
    apiService
        .post(`${endPoints.subscription.calculate_subscription_cost}${renew ? '?renewal=true' : ''}`, data)
        .then((response) => {
            dispatch(subscriptionCalculationSuccess(response.data));
        })
        .catch((error) =>
            dispatch(subscriptionCalculationError(formatAxiosErrorMessage(error)))
        );
};

export const proceedToPayApi = (data, renew = false) => async (dispatch) => {
    dispatch(subscriptionCreationRequesting());
    apiService
        .post(`${endPoints.subscription.proceed_to_pay}${renew ? '?renewal=true' : ''}`, data)
        .then((response) => {
            dispatch(subscriptionCreationSuccess(response.data));
        })
        .catch((error) => {
            dispatch(subscriptionCreationError(formatAxiosErrorMessage(error)));
        });
};

export const getSubscriptionTypesApi = () => async (dispatch) => {
    dispatch(subscriptionTypesRequesting());
    apiService
        .get(endPoints.subscription.fetch_types)
        .then((response) => {
            dispatch(subscriptionTypesSuccess(response.data));
        })
        .catch((error) =>
            dispatch(subscriptionTypesError(formatAxiosErrorMessage(error)))
        );
};

export const getSubscriptionPlansApi = () => async (dispatch) => {
    dispatch(subscriptionPlansRequesting());
    apiService
        .get(endPoints.subscription.fetch_plans)
        .then((response) => {
            dispatch(subscriptionPlansSuccess(response.data));
        })
        .catch((error) =>
            dispatch(subscriptionPlansError(formatAxiosErrorMessage(error)))
        );
};
export const getSubscriptionPaymentsApi = () => async (dispatch) => {
    dispatch(subscriptionPaymentRequesting());
    apiService
        .get(endPoints.subscription.payment)
        .then((response) => {
            dispatch(subscriptionPaymentSuccess(response.data));
        })
        .catch((error) =>
            dispatch(subscriptionPaymentError(formatAxiosErrorMessage(error)))
        );
};

export const DownloadInvoiceRequestAPI = (id) => async (dispatch) => {
    dispatch(downloadInvoiceRequestRequesting());
    let responseURL = `${endPoints.subscription.download}/${id}/`;


    apiService
        .get(responseURL, {
            responseType: 'blob',
        })
        .then((response) => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            const contentDisposition = response.headers['content-disposition'];
            const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
            const filename = filenameMatch ? filenameMatch[1] : 'document_filename.extension';
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Set the filename dynamically
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            dispatch(downloadInvoiceRequestSuccess("downloaded"));
        })
        .catch((error) =>
            dispatch(downloadInvoiceRequestError(formatAxiosErrorMessage(error)))
        );
};

export const {
    subscriptionPlansRequesting,
    subscriptionPlansSuccess,
    subscriptionPlansError,
    subscriptionPlansReset,

    subscriptionTypesRequesting,
    subscriptionTypesSuccess,
    subscriptionTypesError,
    subscriptionTypesReset,

    subscriptionCalculationRequesting,
    subscriptionCalculationSuccess,
    subscriptionCalculationError,
    subscriptionCalculationReset,

    subscriptionDetailsRequesting,
    subscriptionDetailsSuccess,
    subscriptionDetailsError,
    subscriptionDetailsReset,

    subscriptionCreationRequesting,
    subscriptionCreationSuccess,
    subscriptionCreationError,
    subscriptionCreationReset,

    subscriptionPaymentRequesting,
    subscriptionPaymentSuccess,
    subscriptionPaymentError,
    subscriptionPaymentReset,

    downloadInvoiceRequestRequesting,
    downloadInvoiceRequestSuccess,
    downloadInvoiceRequestError,
    downloadInvoiceRequestReset,

    SubscriptionMasterReset,
} = subscriptionSlice.actions;
export const subscriptionState = (state) => state.subscription;
export default subscriptionSlice.reducer;
