import CheckCircle from "../../icon/CheckCircle";
import InformationCircle from "../../icon/InformationCircle";
import CloseCircle from "../../icon/CloseCircle";
import React from "react";
import "./style.css";
import TriangleExclamation from "../../icon/TriangleExclamation";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Alert({
                                  type = "info",
                                  title,
                                  description,
                                  ...props
                              }) {
    const alertClass = () => {
        let css = "alert-box";
        if (type === "warning") {
            css = `${css} alert-warning`;
        } else if (type === "error") {
            css = `${css} alert-error`;
        } else if (type === "success") {
            css = `${css} alert-success`;
        } else if (type === "info") {
            css = `${css} alert-info`;
        }
        return css;
    };

    return (
        <div className={alertClass()} {...props}>
            <div className="d-flex">
                <div className="alert-icon-box">
                    {type === "warning" && (
                        <TriangleExclamation
                            className="alert-icon"
                            aria-hidden="true"
                        />
                    )}
                    {type === "error" && (
                        <CloseCircle className="alert-icon" aria-hidden="true"/>
                    )}
                    {type === "success" && (
                        <CheckCircle
                            className="alert-icon"
                            aria-hidden="true"
                        />
                    )}
                    {type === "info" && (
                        <InformationCircle
                            className="alert-icon"
                            aria-hidden="true"
                        />
                    )}
                </div>
                <div className="alert-message-box">
                    {title &&
                        <h3 className="alert-title">{title}</h3>
                    }
                    {description &&
                        <div className="alert-description">
                            {description}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
